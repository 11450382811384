import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, useTheme } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter'; // Optional: Material UI Twitter Icon


const TwitterFeed = () => {
    const listUrl = "https://x.com/i/lists/1837858743176122433"
    const theme = useTheme();

    const handleClick = () => {
        if (listUrl) {
            window.open(listUrl, '_blank', 'noopener,noreferrer');
        } else {
            console.error('Twitter list URL is not provided.');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh" /* Full viewport height for vertical centering */
            sx={{
                padding: theme.spacing(2),
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={4}
                sx={{
                    maxWidth: 400,
                    width: '100%', // Ensures responsiveness
                    textAlign: 'center',
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? theme.palette.background.paper
                            : '#f5f8fa',
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[3],
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<TwitterIcon />}
                    onClick={handleClick}
                    aria-label="View our Live News X Feed on Twitter"
                    sx={{
                        mb: theme.spacing(2),
                        paddingX: theme.spacing(3),
                        paddingY: theme.spacing(1.5),
                        fontSize: '1rem',
                        textTransform: 'none',
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: theme.shadows[6],
                        },
                    }}
                >
                    View our Live News X Feed
                </Button>
                <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                    sx={{ maxWidth: '80%' }}
                >
                    It's a curated list of X accounts focused on reporting news.
                </Typography>
                {/* Additional Note */}
                <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                    sx={{ mt: theme.spacing(2), maxWidth: '80%' }}
                >
                    To suggest an account to add to the X Feed, send an email to{' '}
                    <a href="mailto:news@newsv2.com" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                        news@newsv2.com
                    </a>
                </Typography>
            </Box>
        </Box>
    );
};

TwitterFeed.propTypes = {
    listUrl: PropTypes.string.isRequired,
};

export default TwitterFeed;
