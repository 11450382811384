import React, {useState, useEffect, useCallback, useRef} from 'react';
import {
    Container,
    Typography,
    Paper,
    Select,
    MenuItem,
    Slider,
    Button,
    Box,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Snackbar,
    IconButton,
    LinearProgress,
    Tooltip,
} from '@mui/material';
import { PlayArrow, Pause, Replay, Add, BookmarkBorder,
    Bookmark,
    Settings,
    Close,Timer } from '@mui/icons-material';

const SpeedReader = () => {
    const theme = useTheme();
    const [transcripts, setTranscripts] = useState([]);
    const [selectedTranscript, setSelectedTranscript] = useState('');
    const [content, setContent] = useState([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [speed, setSpeed] = useState(200);
    const [intervalId, setIntervalId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [customText, setCustomText] = useState('');
    const [bookmarks, setBookmarks] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [fontSize, setFontSize] = useState(16);
    const [estimatedTime, setEstimatedTime] = useState(0);
    const speedTimerRef = useRef(null);

    const showSnackbar = useCallback((message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    }, []);

    const stopReading = useCallback(() => {
        setIsPlaying(false);
        if (intervalId) clearInterval(intervalId);
    }, [intervalId]);

    const startReading = useCallback(() => {
        if (content.length === 0) {
            showSnackbar('No content to read. Please select a valid transcript or add custom text.');
            return;
        }
        setIsPlaying(true);
        const id = setInterval(() => {
            setCurrentWordIndex(prevIndex => {
                if (prevIndex >= content.length - 1) {
                    clearInterval(id);
                    setIsPlaying(false);
                    showSnackbar('Finished reading!');
                    return prevIndex;
                }
                return prevIndex + 1;
            });
        }, 60000 / speed);
        setIntervalId(id);
    }, [content, speed, showSnackbar]);

    const handleSpeedChange = useCallback((event, newValue) => {
        setSpeed(newValue);

        if (speedTimerRef.current) {
            clearTimeout(speedTimerRef.current);
        }

        speedTimerRef.current = setTimeout(() => {
            if (isPlaying) {
                stopReading();
                startReading();
            }
        }, 200);
    }, [isPlaying, startReading, stopReading]);

    useEffect(() => {
        fetchTranscripts();
    }, []);

    useEffect(() => {
        setEstimatedTime(Math.ceil(content.length / speed));
    }, [content, speed]);

    const fetchTranscripts = async () => {
        try {
            const response = await fetch('https://api.newsv2.com/api/recent-files/');
            const data = await response.json();

            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('Invalid or empty data received from API');
            }

            const validData = data.filter(item => item && typeof item.name === 'string');
            const sortedData = validData.sort((a, b) => a.name.localeCompare(b.name)).reverse(); // Alphabetical order
            const topNews = sortedData.find(item => item.name.toLowerCase().includes('top_news'));

            setTranscripts(topNews ? [topNews, ...sortedData.filter(item => item !== topNews)] : sortedData);

            if (topNews) {
                setSelectedTranscript(topNews.name);
                setContent(topNews.transcript ? topNews.transcript.split(/\s+/) : []);
            } else if (sortedData.length > 0) {
                setSelectedTranscript(sortedData[0].name);
                setContent(sortedData[0].transcript ? sortedData[0].transcript.split(/\s+/) : []);
            }
        } catch (error) {
            console.error('Error fetching transcripts:', error);
            showSnackbar('Failed to fetch transcripts. Please try again.');
        }
    };

    const formatTranscriptName = (name) => {
        if (typeof name !== 'string') return 'Untitled';
        return name.replace('.mp3', '')
            .replace(/_/g, ' ')
            .replace(/-/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const handleTranscriptChange = (event) => {
        const value = event.target.value;
        setSelectedTranscript(value);
        if (value === 'custom') {
            setOpenDialog(true);
        } else {
            const selectedContent = transcripts.find(t => t.name === value);
            if (selectedContent && selectedContent.transcript) {
                setContent(selectedContent.transcript.split(/\s+/));
                resetReading();
            } else {
                setContent([]);
                showSnackbar('Selected transcript is empty or invalid.');
            }
        }
    };

    const resetReading = () => {
        setCurrentWordIndex(0);
        setIsPlaying(false);
        if (intervalId) clearInterval(intervalId);
    };

    const handleWordClick = (index) => {
        setCurrentWordIndex(index);
        if (isPlaying) {
            stopReading();
            startReading();
        }
    };

    const handleCustomTextSubmit = () => {
        if (customText.trim()) {
            setContent(customText.trim().split(/\s+/));
            setSelectedTranscript('custom');
            resetReading();
            showSnackbar('Custom text added successfully!');
        } else {
            showSnackbar('Please enter some text before submitting.');
        }
        setOpenDialog(false);
    };

    const toggleBookmark = () => {
        if (bookmarks.includes(currentWordIndex)) {
            setBookmarks(bookmarks.filter(b => b !== currentWordIndex));
            showSnackbar('Bookmark removed');
        } else {
            setBookmarks([...bookmarks, currentWordIndex]);
            showSnackbar('Bookmark added');
        }
    };

    const handleFontSizeChange = (event, newValue) => {
        setFontSize(newValue);
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            {/*<Typography variant="h4" gutterBottom>*/}
            {/*    Enhanced Speed Reader*/}
            {/*</Typography>*/}
            <Paper sx={{ p: 3, mb: 3 }}>
                <Select
                    fullWidth
                    value={selectedTranscript}
                    onChange={handleTranscriptChange}
                    displayEmpty
                    sx={{ mb: 2 }}
                >
                    <MenuItem value="" disabled>Select News or Paste in Article</MenuItem>
                    {transcripts.map((transcript) => (
                        <MenuItem key={transcript.id || transcript.name} value={transcript.name}>
                            {formatTranscriptName(transcript.name)}
                        </MenuItem>
                    ))}
                    <MenuItem value="custom">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Add sx={{ mr: 1 }} />
                            Custom Text
                        </Box>
                    </MenuItem>
                </Select>
                <Typography id="speed-slider" gutterBottom>
                    <b>Drag</b> slider to preferred Reading Speed: {speed} words per minute
                </Typography>
                <Slider
                    value={speed}
                    onChange={handleSpeedChange}
                    aria-labelledby="speed-slider"
                    min={100}
                    max={600}
                    step={10}
                    sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                    <Button
                        variant="contained"
                        startIcon={isPlaying ? <Pause /> : <PlayArrow />}
                        onClick={isPlaying ? stopReading : startReading}
                        disabled={content.length === 0}
                    >
                        {isPlaying ? 'Pause' : 'Start'}
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<Replay />}
                        onClick={resetReading}
                        disabled={content.length === 0 || currentWordIndex === 0}
                    >
                        Reset
                    </Button>
                    <Tooltip title="Toggle Bookmark">
                        <IconButton onClick={toggleBookmark} disabled={content.length === 0}>
                            {bookmarks.includes(currentWordIndex) ? <Bookmark /> : <BookmarkBorder />}
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography id="font-size-slider" gutterBottom>
                    Font Size: {fontSize}px
                </Typography>
                <Slider
                    value={fontSize}
                    onChange={handleFontSizeChange}
                    aria-labelledby="font-size-slider"
                    min={12}
                    max={32}
                    step={1}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography variant="body2">
                        Word Count: {content.length}
                    </Typography>
                    <Tooltip title="Estimated Reading Time">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Timer sx={{ mr: 1 }} />
                            <Typography variant="body2">
                                {estimatedTime} min
                            </Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </Paper>
            <Paper sx={{ p: 3, mb: 3, minHeight: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h2" align="center" sx={{ fontSize: `${fontSize * 2}px` }}>
                    {content[currentWordIndex] || "Select a transcript or add custom text to begin"}
                </Typography>
            </Paper>
            <LinearProgress variant="determinate" value={content.length > 0 ? (currentWordIndex / (content.length - 1)) * 100 : 0} sx={{ mb: 3 }} />
            <Paper sx={{ p: 3, maxHeight: 400, overflow: 'auto' }}>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                    }}
                >
                    {content.map((word, index) => (
                        <span
                            key={index}
                            onClick={() => handleWordClick(index)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: index === currentWordIndex ? theme.palette.primary.main :
                                    bookmarks.includes(index) ? theme.palette.secondary.main : 'transparent',
                                color: (index === currentWordIndex || bookmarks.includes(index)) ? theme.palette.primary.contrastText : 'inherit',
                                padding: '0 2px',
                                margin: '0 1px',
                                borderRadius: '2px',
                                display: 'inline-block',
                                fontSize: `${fontSize}px`,
                            }}
                        >
              {word}
            </span>
                    ))}
                </Typography>
            </Paper>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Add Custom Text</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="custom-text"
                        label="Paste or type your text here"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={customText}
                        onChange={(e) => setCustomText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleCustomTextSubmit}>Add</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
        </Container>
    );
};

export default SpeedReader;
