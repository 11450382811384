// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import { Container, Box, IconButton, Typography,Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
// import { styled } from '@mui/system';
// import AudioMotionAnalyzer from 'audiomotion-analyzer';
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Container, Box, IconButton, Typography, Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { styled } from '@mui/system';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { RHAP_UI } from 'react-h5-audio-player';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SpeakerNotes from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOff from '@mui/icons-material/SpeakerNotesOff';
import { useTheme } from '@mui/material/styles';
import ModalComponent from "./Modal";
import SpeedControl from "./SpeedControl";
import DescriptionIcon from "@mui/icons-material/Description";


const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledAudioControls = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    // marginTop: theme.spacing(2),
}));

const TranscriptBox = styled(Box)(({ theme }) => ({
    // marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    maxHeight: '300px',
    overflowY: 'auto'
}));

const LiveTranscriptBox = styled(Box)(({ theme }) => ({
    minHeight: '30px',
    maxHeight: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ScrollingText = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    transition: 'transform 1.2s linear',
    fontSize: '20px'
}));

const CustomAudioPlayer = React.forwardRef(({ src, onPlay, onPause, onEnded, onTimeUpdate }, ref) => (
    <AudioPlayer
        ref={ref}
        src={src}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onListen={onTimeUpdate}
        showJumpControls={true}
        layout="stacked"
        autoPlayAfterSrcChange={false}
        crossOrigin="anonymous"

        style={{ backgroundColor: 'inherit', color: 'inherit' }}
        // className="custom-audio-player"

    />
));




function PrivacyPolicy(props) {

    const [isPlaying, setIsPlaying] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(50);
    const [showTranscript, setShowTranscript] = useState(true);
    const audioRef = useRef(null);
    const containerRef = useRef(null);
    const audioMotionRef = useRef(null);
    const [category, setCategory] = useState('Business');
    // const [transcript, setTranscript] = useState('Yes, I use google analytics to track how you use the site, thats the only user information I store and its Anonymous. I want to see how many people use the website, its not available in Europe so don\'t get me started on the cookie tracking and what not. I just wanna know how people are using the app, all information collected is anonymous, I don\'t know who you are. It wont take long for you to look up who made the site so if anything I\'m putting myself at risk making this, where\'s my privacy policy?');
    const [transcript, setTranscript] = useState("News v2 puts user privacy as a top priority, this privacy policy explains how we handle your information when you visit newsv2.com. The only data we collect is from Google analytics. This helps us understand how users interact with the website. But do not worry as all this infomation is anonymized and cant be used to identify anyone. Google analytics uses cookies to track users interactions, you can disable cookies through your browser settings if you prefer not to allow this tracking. We do not share any data with third parties except google analytics. Google’s ability to use and share information collected by Google Analytics about your visits to this site is restricted by the Google Analytics Terms of Service and the Google Privacy Policy. News v2 protects the data collected through google analytics from unauthorized access or disclosure. However, no internet transmission is ever completely secure so we can't guarantee absolute security. News v2 may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We’ll let you know about any significant changes by posting the new policy on our site. If you have any questions or concerns about this Privacy Policy feel free to reach out to news@newsv2.com, always happy to answer any questions. News v2 wants to ensure you have a fun and worry-free experience on our website. Rest assured News v2 is commited to keeping your experience private and secure, while making the website the best it can be. Enjoy your time on News v2! This privacy policy is effective as of July 14th 2024.");
    const [openModal, setOpenModal] = useState(false);

    const theme = useTheme();

    const color = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';


    const handleModalOpen = () => {
        setOpenModal(true);
        setShowTranscript(false);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const initializeAudioMotionAnalyzer = () => {
        if (audioRef.current && containerRef.current && !audioMotionRef.current) {
            audioMotionRef.current = new AudioMotionAnalyzer(containerRef.current, {
                source: audioRef.current.audio.current,

                height: 300,
                ansiBands: false,
                showScaleX: false,
                bgAlpha: 0,
                overlay: true,
                mode: 7,
                frequencyScale: "bark",
                showPeaks: true,
                smoothing: 0.1,
                ledBars: true,
                mirror:0,
                gradient:"orangered",

            });

        }
    };
    const handlePlay = () => {
        if (audioRef.current && audioRef.current.audio.current.readyState >= 2) {
            initializeAudioMotionAnalyzer();
            setIsPlaying(true);
            audioRef.current.audio.current.play();
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        if (audioRef.current) {
            audioRef.current.audio.current.currentTime = 0;
            audioRef.current.audio.current.pause();
        }
    };
    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.audio.current.currentTime;
            const duration = audioRef.current.audio.current.duration;

            const progress = currentTime / duration;
            const transcriptLength = transcript.length;
            const displayedLength = Math.floor(progress * transcriptLength);
            const newPosition = 50 - (progress * 101);
            if (!isNaN(newPosition)) {
                setScrollPosition(newPosition);
            }
        }
    };
    const toggleTranscriptVisibility = () => {
        setShowTranscript((prev) => !prev);
    };

    return (
        <div>
            <StyledContainer>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        color: color, // Light grey color with low opacity
                        textAlign: 'center', // Center the text
                        // fontStyle: 'italic' // Optional: Add some style to make it more mysterious
                    }}
                >
                    Privacy Policy
                </Typography>

                <Box ref={containerRef} style={{ width: '100%', height: '300px' }} />



                <LiveTranscriptBox>
                    {isPlaying && (
                        <ScrollingText variant="body1" style={{ transform: `translateX(${scrollPosition}%)` }}>
                            {transcript}
                        </ScrollingText>
                    )}
                </LiveTranscriptBox>

                <Box display="flex" justifyContent="center" alignItems="center" gap=".5rem">
                    <CustomAudioPlayer

                        ref={audioRef}
                        src={"https://d3k64dniyspny7.cloudfront.net/privacypolicy.mp3"}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onEnded={handleEnded}
                        onTimeUpdate={handleTimeUpdate}
                    />


                </Box>
                <Box display="flex" justifyContent="center" style={{marginTop:"5px", marginBottom:"5px"}}  gap="1rem">

                    <Tooltip title={showTranscript ? 'Hide Transcript' : 'Show Transcript'}>
                        <IconButton onClick={toggleTranscriptVisibility}>
                            {showTranscript ? <SpeakerNotesOff /> : <SpeakerNotes />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Full Screen View Transcript"}>

                        <IconButton  onClick={handleModalOpen}>
                            <DescriptionIcon />
                        </IconButton>
                    </Tooltip>
                    <SpeedControl audioRef={audioRef} />
                </Box>

                {showTranscript && (
                    <TranscriptBox>
                        <Typography variant="body1">{transcript}</Typography>
                    </TranscriptBox>
                )}
                <ModalComponent open={openModal} handleClose={handleModalClose} title={"Privacy Policy"} content={transcript} />


            </StyledContainer>

        </div>
    );
}

export default PrivacyPolicy;
