import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link as RouterLink } from 'react-router-dom';
import { IconButton, Typography, Menu, MenuItem, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LightMode from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Home from './components/Home';
import About from "./components/About";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import './App.css';
import LatestNews from "./components/LatestNews";
import BusinessNews from "./components/BusinessNews";
import TopNews from "./components/TopNews";
import Subscribe from "./components/Subscribe";
import Unsubscribe from "./components/Unsubscribe";
import ConfirmSubscription from "./components/ConfirmSubscription";
import ConfirmUnsubscription from "./components/ConfirmUnsubscribe";
import Link from '@mui/material/Link';
import Game from "./components/SpeedReader";
import SpeedReader from "./components/SpeedReader";
import TwitterFeed from "./components/TwitterFeed";


const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = useState(prefersDarkMode);
    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                    primary: {
                        main: '#ff6700', // Neon orange color
                    },
                    secondary: {
                        main: darkMode ? '#1abc9c' : '#2980b9', // Teal for dark mode, Blue for light mode
                    },
                    background: {
                        default: darkMode ? '#121212' : '#f5f5f5', // Dark grey for dark mode, Light grey for light mode
                        paper: darkMode ? '#1e1e1e' : '#ffffff', // Slightly lighter than default background
                    },
                    text: {
                        primary: darkMode ? '#e0e0e0' : '#333333', // Light text for dark mode, Dark text for light mode
                        secondary: darkMode ? '#b0b0b0' : '#555555', // Secondary text colors
                    },
                },
                typography: {
                    fontFamily: darkMode
                        ? 'Space Grotesk, sans-serif'
                        : 'Inter, sans-serif',
                    h1: {
                        fontFamily: darkMode
                            ? 'Space Grotesk, sans-serif'
                            : 'Inter, sans-serif',
                        fontWeight: 700,
                    },
                    h2: {
                        fontFamily: darkMode
                            ? 'Space Grotesk, sans-serif'
                            : 'Inter, sans-serif',
                        fontWeight: 700,
                    },
                    body1: {
                        fontFamily: darkMode
                            ? 'Space Grotesk, sans-serif'
                            : 'Inter, sans-serif',
                    },
                    body2: {
                        fontFamily: darkMode
                            ? 'Fira Code, monospace'
                            : 'IBM Plex Mono, monospace',
                    },
                    button: {
                        fontFamily: darkMode
                            ? 'Fira Code, monospace'
                            : 'IBM Plex Mono, monospace',
                    },
                },
            }),
        [darkMode],
    );

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleThemeToggle = () => {
        setDarkMode((prevMode) => !prevMode);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={darkMode ? 'dark-mode app-container' : 'light-mode app-container'}>
                <Router>
                    <div className="content-container">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/top" element={<TopNews />} />
                            <Route path="/business" element={<BusinessNews />} />
                            <Route path="/latest" element={<LatestNews />} />
                            <Route path="/subscribe" element={<Subscribe />} />
                            <Route path="/unsubscribe" element={<Unsubscribe />} />
                            <Route path="/confirm-subscription" element={<ConfirmSubscription />} />
                            <Route path="/confirm-unsubscription" element={<ConfirmUnsubscription />} />
                            <Route path="/read" element={<SpeedReader />} />
                            <Route path="/newsfeed" element={<TwitterFeed />} />


                        </Routes>
                    </div>
                    <footer className="footer">
                        <Typography variant="body1" style={{marginLeft: '20px'}}>

                            <Link component={RouterLink} to="/" underline="none">
                                News v2 © {new Date().getFullYear()}</Link>
                        </Typography>
                        <div className="footer-menu">
                            <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/">News v2</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/latest">Latest News</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/subscribe">Newsletter</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/read">Speed Reader</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/newsfeed">X Feed</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/about">About</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/contact">Contact</MenuItem>
                                <MenuItem onClick={handleMenuClose} component={RouterLink} to="/privacy-policy">Privacy Policy</MenuItem>
                            </Menu>
                            <IconButton edge="end" color="inherit" onClick={handleThemeToggle}>
                                {darkMode ? <LightMode style={{ fontSize: '1.5rem' }} /> : <Brightness3Icon style={{ fontSize: '1.5rem' }} />}
                            </IconButton>
                        </div>
                    </footer>
                </Router>
            </div>
        </ThemeProvider>
    );
};

export default App;
