// src/components/Subscribe.js

import React, { useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Typography,
    Container,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import { getCookie } from '../utils';
import {Link} from "react-router-dom";
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {ContactMail} from "@mui/icons-material"; // Optional: Add an icon for better UX


// const backend_url = "http://localhost:8000"
const backend_url = "https://api.newsv2.com"

function Subscribe() {
    const [email, setEmail] = useState('');
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 'success' | 'error' | 'warning' | 'info'
    });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
        const token = await window.grecaptcha.enterprise.execute('6Ld4akoqAAAAANqXxxnfKSzqs_u5tQX-r00rdXCp', { action: 'submit' });
        // console.log(token);

        // Client-side email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setSnackbar({
                open: true,
                message: 'Email is required.',
                severity: 'warning',
            });
            return;
        }
        if (!emailRegex.test(email)) {
            setSnackbar({
                open: true,
                message: 'Invalid email address.',
                severity: 'error',
            });
            return;
        }

        try {
            const csrfToken = getCookie('csrftoken'); // If CSRF protection is enabled
            const response = await axios.post(
                backend_url+'/api/subscribe',
                { email, captcha:token },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'X-CSRFToken': csrfToken, // Uncomment if CSRF token is required
                    },
                }
            );
            setSnackbar({
                open: true,
                message: response.data.message,
                severity: 'success',
            });
            setEmail(''); // Reset the email field
        } catch (error) {
            setSnackbar({
                open: true,
                message:
                    error.response?.data?.error ||
                    'An error occurred. Please try again later.',
                severity: 'error',
            });
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8 }}>
                <Typography variant="h4" gutterBottom>
                    Subscribe to our Newsletter
                </Typography>
                <form onSubmit={handleSubscribe}>
                    <TextField
                        label="Email"
                        type="email"
                        required
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        sx={{ mt: 2 }}
                        startIcon={<ContactMail />} // Optional: Adds an icon to the button

                    >
                        Subscribe
                    </Button>
                </form>
                <Typography variant="body1" style={{marginTop:"40px"}} gutterBottom>
                    Our newsletter delivers top news stories in Business, Sports, Tech, Health and Science straight to your inbox with no ads.
                    </Typography>
                <Typography variant="body1" style={{marginTop:"20px"}} gutterBottom>
                    We do not use newsletter providers who advertise either, no ads, ever.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Link component={Link} to="/unsubscribe" underline="hover">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<UnsubscribeIcon />} // Optional: Adds an icon to the button
                            sx={{
                                // Override any default styling if necessary
                                // For example, adjust padding or font size
                            }}
                        >
                            Unsubscribe
                        </Button>
                    </Link>
                </Box>

            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Subscribe;
