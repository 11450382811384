// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import { Container, Box, IconButton, Typography,Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
// import { styled } from '@mui/system';
// import AudioMotionAnalyzer from 'audiomotion-analyzer';
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Container, Box, IconButton, Typography, Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { styled } from '@mui/system';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { RHAP_UI } from 'react-h5-audio-player';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SpeakerNotes from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOff from '@mui/icons-material/SpeakerNotesOff';
import { useTheme } from '@mui/material/styles';
import ModalComponent from "./Modal";
import DescriptionIcon from "@mui/icons-material/Description";
import SpeedControl from "./SpeedControl";


const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const StyledAudioControls = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    // marginTop: theme.spacing(2),
}));

const TranscriptBox = styled(Box)(({ theme }) => ({
    // marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    maxHeight: '300px',
    overflowY: 'auto'
}));

const LiveTranscriptBox = styled(Box)(({ theme }) => ({
    minHeight: '30px',
    maxHeight: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ScrollingText = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    transition: 'transform 1.2s linear',
    fontSize: '20px'
}));

const CustomAudioPlayer = React.forwardRef(({ src, onPlay, onPause, onEnded, onTimeUpdate }, ref) => (
    <AudioPlayer
        ref={ref}
        src={src}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onListen={onTimeUpdate}
        showJumpControls={true}
        layout="stacked"
        autoPlayAfterSrcChange={false}
        crossOrigin="anonymous"

        style={{ backgroundColor: 'inherit', color: 'inherit',  }}
        // className="custom-audio-player"

    />
));




function About(props) {

    const [isPlaying, setIsPlaying] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(50);
    const [showTranscript, setShowTranscript] = useState(true);
    const audioRef = useRef(null);
    const containerRef = useRef(null);
    const audioMotionRef = useRef(null);
    const [category, setCategory] = useState('Business');
    const [transcript, setTranscript] = useState("Welcome to News v2 – a new version of the news, completely ad-free and dedicated to providing reliable reporting of current events. This website is designed to offer a fresh experience in how people consume news by delivering trustworthy information in a timely manner without any distractions or alternative motives. Through audio recordings and straightforward news reports, we aim to provide a more simplistic and enjoyable way to stay informed. Thank you for visiting our site. News v2 is excited to present this innovative approach to news reporting, prioritizing clarity and reliability to ensure you receive the information you need without the clutter of advertisements. We appreciate your support and hope you enjoy this refreshing method of staying updated.");
    // const [transcript, setTranscript] = useState('Welcome to News v2 - a new version of the news, no ads, no opinions, just reliable news reporting of current events. This website is dedicated to providing a new experience for how people consume news. Our goal is to provide reliable news reporting, in a timely fashion, without distractions, without alternative motives, just news. Delivered to users via audio recordings and news reports for a more simplistic feel.');
    const [openModal, setOpenModal] = useState(false);

    const theme = useTheme();

    const color = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';




    const initializeAudioMotionAnalyzer = () => {
        if (audioRef.current && containerRef.current && !audioMotionRef.current) {
            audioMotionRef.current = new AudioMotionAnalyzer(containerRef.current, {
                source: audioRef.current.audio.current,

                height: 300,
                ansiBands: false,
                showScaleX: false,
                bgAlpha: 0,
                overlay: true,
                mode: 7,
                frequencyScale: "bark",
                showPeaks: true,
                smoothing: 0.1,
                ledBars: true,
                mirror:0,
                gradient:"orangered",

            });

        }
    };
    const handlePlay = () => {
        if (audioRef.current && audioRef.current.audio.current.readyState >= 2) {
            initializeAudioMotionAnalyzer();
            setIsPlaying(true);
            audioRef.current.audio.current.play();
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        if (audioRef.current) {
            audioRef.current.audio.current.currentTime = 0;
            audioRef.current.audio.current.pause();
        }
    };
    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.audio.current.currentTime;
            const duration = audioRef.current.audio.current.duration;

            const progress = currentTime / duration;
            const transcriptLength = transcript.length;
            const displayedLength = Math.floor(progress * transcriptLength);
            const newPosition = 50 - (progress * 101);
            if (!isNaN(newPosition)) {
                setScrollPosition(newPosition);
            }
        }
    };
    const toggleTranscriptVisibility = () => {
        setShowTranscript((prev) => !prev);
    };

    const handleModalOpen = () => {
        setOpenModal(true);
        setShowTranscript(false);

    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    return (
        <div>
            <StyledContainer>
                <Typography
                     variant="h6"
                     gutterBottom
                     sx={{
                         color: color, // Light grey color with low opacity
                         textAlign: 'center', // Center the text
                         // fontStyle: 'italic' // Optional: Add some style to make it more mysterious
                     }}
                 >
                     About
                 </Typography>

                <Box ref={containerRef} style={{ width: '100%', height: '300px' }} />



                <LiveTranscriptBox>
                    {isPlaying && (
                        <ScrollingText variant="body1" style={{ transform: `translateX(${scrollPosition}%)` }}>
                            {transcript}
                        </ScrollingText>
                    )}
                </LiveTranscriptBox>

                <Box display="flex" justifyContent="center" alignItems="center" marginTop="15px" gap=".5rem">
                    <CustomAudioPlayer

                        ref={audioRef}
                        src={"https://d3k64dniyspny7.cloudfront.net/about.mp3"}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onEnded={handleEnded}
                        onTimeUpdate={handleTimeUpdate}
                    />


                </Box>
                <Box display="flex" justifyContent="center" style={{marginTop:"5px", marginBottom:"5px"}} gap="1rem">

                    <Tooltip title={showTranscript ? 'Hide Transcript' : 'Show Transcript'}>
                        <IconButton onClick={toggleTranscriptVisibility}>
                            {showTranscript ? <SpeakerNotesOff /> : <SpeakerNotes />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Full Screen View Transcript"}>

                        <IconButton  onClick={handleModalOpen}>
                            <DescriptionIcon />
                        </IconButton>
                    </Tooltip>
                    <SpeedControl audioRef={audioRef} />

                </Box>
                {showTranscript && (
                    <TranscriptBox>
                        <Typography variant="body1">{transcript}</Typography>
                    </TranscriptBox>
                )}
                <ModalComponent open={openModal} handleClose={handleModalClose} title={"About"} content={transcript} />


            </StyledContainer>

        </div>
    );
}

export default About;
