// src/components/ConfirmUnsubscription.js

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
    Typography,
    Container,
    Box,
    CircularProgress,
    Snackbar,
    Alert, Button,
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';

// const backend_url = "http://localhost:8000"
const backend_url = "https://api.newsv2.com"


function ConfirmUnsubscription() {
    const [status, setStatus] = useState({
        loading: true,
        message: '',
        severity: 'success',
    });
    const location = useLocation();
    const hasConfirmed = useRef(false); // Ref to track confirmation status

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        // console.log("Confirm Unsubscription Token:", token); // Debugging

        if (!token) {
            setStatus({
                loading: false,
                message: 'No token provided.',
                severity: 'error',
            });
            return;
        }

        // Prevent duplicate API calls
        if (hasConfirmed.current) {
            return;
        }
        hasConfirmed.current = true;

        const confirmUnsubscription = async () => {
            try {
                const response = await axios.get(
                    backend_url+`/api/confirm-unsubscription`,
                    {
                        params: { token },
                    }
                );
                setStatus({
                    loading: false,
                    message: response.data.message,
                    severity: 'success',
                });
            } catch (error) {
                setStatus({
                    loading: false,
                    message:
                        error.response?.data?.error ||
                        'An error occurred. Please try again later.',
                    severity: 'error',
                });
                console.error("Confirm Unsubscription Error:", error); // Debugging
            }
        };

        confirmUnsubscription();
    }, [location.search]);

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8, textAlign: 'center' }}>
                {status.loading ? (
                    <>
                        <CircularProgress />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Confirming your unsubscription...
                        </Typography>
                    </>
                ) : (
                    <Typography variant="h5">{status.message}</Typography>
                )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Link component={Link} to="/" underline="hover">
                    <Button
                        variant="contained"
                        color="primary"
                        // startIcon={<UnsubscribeIcon />} // Optional: Adds an icon to the button
                        sx={{
                            // Override any default styling if necessary
                            // For example, adjust padding or font size
                        }}
                    >
                        Return to News v2
                    </Button>
                </Link>
            </Box>
            <Snackbar
                open={!!status.message && !status.loading}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={status.severity} sx={{ width: '100%' }}>
                    {status.message}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ConfirmUnsubscription;
