
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Container, Box, IconButton, Typography, Button, MenuItem, FormControl, Select, InputLabel, Dialog, DialogTitle,DialogContent,DialogContentText,DialogActions } from '@mui/material';

import { styled } from '@mui/system';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Tooltip from '@mui/material/Tooltip';
import SpeakerNotes from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOff from '@mui/icons-material/SpeakerNotesOff';
import { useTheme } from '@mui/material/styles';
import ModalComponent from "./Modal";
import DescriptionIcon from '@mui/icons-material/Description';
import SpeedControl from "./SpeedControl";
import AIWarningAlert from "./AIWarningAlert";
import {useNavigate} from "react-router-dom";
import {Book, FormatListNumbered, LiveTv, MenuBook} from "@mui/icons-material";
import LatestNews from "./LatestNews";
// import LatestNews from "./LatestNews";
const cloudFront = "https://d3k64dniyspny7.cloudfront.net/"
// const cloudFront = "http://localhost:8000/media/"
const backend = "https://api.newsv2.com/"
// const backend = "http://localhost:8000/"

const StyledContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    maxWidth: '1000px',
    margin: '0 auto',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const LiveTranscriptBox = styled(Box)(({ theme }) => ({
    minHeight: '30px',
    maxHeight: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ScrollingText = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    whiteSpace: 'nowrap',
    transition: 'transform 1.2s linear',
    fontSize: '20px',
    fontWeight: 'bold',
}));

const TranscriptBox = styled(Box)(({ theme }) => ({
    maxHeight: '300px',
    overflowY: 'auto',
    marginTop: '20px',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    lineHeight: '0px',
}));

const CustomAudioPlayer = React.forwardRef(({ src, onPlay, onPause, onEnded, onTimeUpdate, handlePlayClick }, ref) => (
    <AudioPlayer
        ref={ref}
        src={src}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onListen={onTimeUpdate}
        showJumpControls={true}
        layout="stacked"
        autoPlayAfterSrcChange={false}
        autoPlay={false}
        crossOrigin="anonymous"
        style={{ backgroundColor: 'inherit', color: 'inherit' }}
    />
));

const AudioPlayerPro = () => {
    const [audioFiles, setAudioFiles] = useState([]);
    const [selectedAudio, setSelectedAudio] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(50);
    const [showTranscript, setShowTranscript] = useState(false);
    const [category, setCategory] = useState('Top');
    const [transcript, setTranscript] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const audioRef = useRef(null);
    const containerRef = useRef(null);
    const audioMotionRef = useRef(null);
    const transcriptBoxRef = useRef(null);

    const navigate = useNavigate();
    const theme = useTheme();
    const color = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';

    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        fetchAudioFiles();
    }, [category]);

    useEffect(() => {
        const fetchTranscript = async () => {
            if (!selectedAudio) return;
            const filename = selectedAudio.replace(cloudFront, '');
            try {
                const response = await axios.get(`${backend}api/file-detail/${filename}/`);
                setTranscript(response.data.transcript);
                setScrollPosition(50);
                setHighlightedIndex(0);
            } catch (error) {
                console.error("Error fetching file details:", error);
            }
        };

        fetchTranscript();
    }, [selectedAudio]);

    useEffect(() => {
        if (transcriptBoxRef.current) {
            const highlightedElement = transcriptBoxRef.current.querySelector('.highlighted');
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [highlightedIndex]);

    const fetchAudioFiles = async () => {
        try {
            const response = await axios.get(`${backend}api/files/category/${category}`);
            const sortedFiles = response.data.sort((a, b) => {
                const dateA = extractDate(a.name);
                const dateB = extractDate(b.name);

                if (!dateA || !dateB) {
                    console.error("Invalid date format in file name:", a.name, b.name);
                    return 0;
                }

                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;

                const periodA = extractPeriod(a.name);
                const periodB = extractPeriod(b.name);

                if (periodA === 'evening' && periodB === 'morning') return -1;
                if (periodA === 'morning' && periodB === 'evening') return 1;

                return 0;
            });

            setAudioFiles(sortedFiles);
            const firstAudio = sortedFiles[0] ? sortedFiles[0].name : '';
            setSelectedAudio(`${cloudFront}${firstAudio}`);

            if (audioRef.current && firstAudio) {
                audioRef.current.audio.current.src = `${cloudFront}${firstAudio}`;
            }
        } catch (error) {
            console.error("Error fetching audio files:", error);
        }
    };

    const extractDate = (fileName) => {
        const datePart = fileName.split('_').pop().replace('.mp3', '');
        const [month, day, year] = datePart.split('-');
        if (month && day && year) {
            return new Date(`${year}-${month}-${day}`);
        }
        return null;
    };

    const extractPeriod = (fileName) => {
        if (fileName.toLowerCase().includes('evening')) {
            return 'evening';
        }
        if (fileName.toLowerCase().includes('morning')) {
            return 'morning';
        }
        return null;
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setIsPlaying(false);
    };

    const initializeAudioMotionAnalyzer = () => {
        if (audioRef.current && containerRef.current && !audioMotionRef.current) {
            audioMotionRef.current = new AudioMotionAnalyzer(containerRef.current, {
                source: audioRef.current.audio.current,
                height: 300,
                ansiBands: false,
                showScaleX: false,
                bgAlpha: 0,
                overlay: true,
                mode: 6,
                frequencyScale: "bark",
                showPeaks: true,
                smoothing: 0.1,
                ledBars: true,
                mirror: 0,
                gradient: "orangered",
            });
        }
    };

    const handleChange = (event) => {
        const audioUrl = event.target.value;
        setSelectedAudio(audioUrl);
        if (audioRef.current) {
            audioRef.current.audio.current.pause();
            setIsPlaying(false);
            audioRef.current.audio.current.src = audioUrl;
            audioRef.current.audio.current.load();
        }
    };

    const handlePlay = () => {
        if (audioRef.current && audioRef.current.audio.current.readyState >= 2) {
            initializeAudioMotionAnalyzer();
            setIsPlaying(true);
            audioRef.current.audio.current.play();
        }
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
        if (audioRef.current) {
            audioRef.current.audio.current.currentTime = 0;
            audioRef.current.audio.current.pause();
        }
    };

    const handlePlayClick = () => {
        if (!isPlaying) {
            handlePlay();
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.audio.current.currentTime;
            const duration = audioRef.current.audio.current.duration;

            const progress = currentTime / duration;
            const transcriptLength = transcript.length;
            const newHighlightedIndex = Math.floor(progress * transcriptLength);
            setHighlightedIndex(newHighlightedIndex+14);

            const newPosition = 50 - (progress * 100);
            if (!isNaN(newPosition)) {
                setScrollPosition(newPosition);
            }
        }
    };

    const toggleTranscriptVisibility = () => {
        setShowTranscript((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);
    const openNewsModal = () => setIsModalOpen(true);
    const closeNewsModal = () => setIsModalOpen(false);

    const HighlightedTranscript = ({ transcript, highlightedIndex }) => {
        return (
            <Typography variant="body1" style={{fontSize: 18}}>
        <span style={{ backgroundColor: 'rgba(255, 165, 0, 0.3)' }}>
          {transcript.slice(0, highlightedIndex)}
        </span>
                <span>{transcript.slice(highlightedIndex)}</span>
            </Typography>
        );
    };

    return (
        <StyledContainer>
            <Typography variant="h6" gutterBottom sx={{ color: color, textAlign: 'center' }}>
                News v2
            </Typography>

            <Box ref={containerRef} style={{ width: '100%', height: '300px' }} />

            <LiveTranscriptBox>
                {isPlaying && (
                    <ScrollingText variant="body1" style={{ transform: `translateX(${scrollPosition}%)` }}>
                        {transcript}
                    </ScrollingText>
                )}
            </LiveTranscriptBox>

            <StyledFormControl gap=".5rem" fullWidth>
                <InputLabel id="audio-select-label">News Report</InputLabel>
                <Select
                    labelId="audio-select-label"
                    value={selectedAudio}
                    onChange={handleChange}
                    label="Select Audio"
                >
                    {audioFiles.slice(0, 14).map((file, index) => (
                        <MenuItem key={index} value={`${cloudFront}${file.name}`}>
                            {file.name.replace(category, "").replace(/_/g, ' ').replace('.mp3', '')}
                        </MenuItem>
                    ))}
                </Select>
            </StyledFormControl>

            <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:"10px",marginBottom:"10px"}}>
                <FormControl variant="outlined" sx={{ minWidth: 100 }} fullWidth>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                        labelId="category-label"
                        value={category}
                        onChange={handleCategoryChange}
                        label="Category"
                    >
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="Health">Health</MenuItem>
                        <MenuItem value="Science">Science</MenuItem>
                        <MenuItem value="Sports">Sports</MenuItem>
                        <MenuItem value="Technology">Technology</MenuItem>
                        <MenuItem value="Top">Top Headlines</MenuItem>
                    </Select>
                </FormControl>
                <Box display="flex" justifyContent="center">
                    <IconButton onClick={openNewsModal}>
                        <FormatListNumbered />
                    </IconButton>
                    <Tooltip title={showTranscript ? 'Hide Transcript' : 'Show Transcript'}>
                        <IconButton onClick={toggleTranscriptVisibility}>
                            {showTranscript ? <SpeakerNotesOff /> : <SpeakerNotes />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Full Screen View Transcript"}>
                        <IconButton onClick={handleModalOpen}>
                            <DescriptionIcon />
                        </IconButton>
                    </Tooltip>
                    <SpeedControl audioRef={audioRef} />
                </Box>
            </Box>

            <CustomAudioPlayer
                ref={audioRef}
                src={selectedAudio}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                onTimeUpdate={handleTimeUpdate}
                handlePlayClick={handlePlayClick}
            />

            <AIWarningAlert />

            {showTranscript && (
                <TranscriptBox ref={transcriptBoxRef}>
                    <HighlightedTranscript transcript={transcript} highlightedIndex={highlightedIndex} />
                </TranscriptBox>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Welcome to News v2</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This app hasn't been optimized for mobile devices. To activate the live transcript and audio
                        visualization.<p><b style={{fontSize: "20px"}}>Click the Play Button Twice</b></p> We are
                        currently building a mobile app for News v2 - for a more optimized experience on mobile
                        platforms. <p><b>Access Newsv2.com from your computer for full functionality.</b></p>
                        <p>Click below to get the latest news</p>
                    </DialogContentText>
                    <Box display="flex" justifyContent="center" marginTop={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<FormatListNumbered />}
                            onClick={() => navigate("/latest")}
                        >
                            Latest News
                        </Button>

                    </Box>
                    <Box display="flex" justifyContent="center" marginTop={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MenuBook />}
                        onClick={() => navigate("/read")}
                    >
                        Speed Read News
                    </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <ModalComponent
                open={isModalOpen}
                handleClose={closeNewsModal}
                title="Latest News"
                content={<LatestNews />}
            />

            <ModalComponent
                open={openModal}
                handleClose={handleModalClose}
                title={selectedAudio.replace(cloudFront, '').replace(/_/g, ' ').replace('.mp3', '')}
                content={<HighlightedTranscript transcript={transcript} highlightedIndex={highlightedIndex} />}
            />
        </StyledContainer>
    );
};

export default AudioPlayerPro;
// const CustomAudioPlayer = React.forwardRef(({ src, onPlay, onPause, onEnded, onTimeUpdate, handlePlayClick }, ref) => (
//     <AudioPlayer
//         ref={ref}
//         src={src}
//         onPlay={onPlay}
//         onPause={onPause}
//         onEnded={onEnded}
//         onListen={onTimeUpdate}
//         showJumpControls={true}
//         layout="stacked"
//         autoPlayAfterSrcChange={false}
//         autoPlay={false}
//         crossOrigin="anonymous"
//         // onTouchStart={handlePlayClick}
//
//
//         style={{ backgroundColor: 'inherit', color: 'inherit' }}
//         // className="custom-audio-player"
//
//     />
// ));
//
// const AudioPlayerPro = () => {
//     const [audioFiles, setAudioFiles] = useState([]);
//     const [selectedAudio, setSelectedAudio] = useState('');
//     const [isPlaying, setIsPlaying] = useState(false);
//     const [scrollPosition, setScrollPosition] = useState(50);
//     const [showTranscript, setShowTranscript] = useState(false);
//     const audioRef = useRef(null);
//     const containerRef = useRef(null);
//     const audioMotionRef = useRef(null);
//     const [category, setCategory] = useState('Top');
//     const [transcript, setTranscript] = useState('');
//     const [openModal, setOpenModal] = useState(false);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//
//     const openNewsModal = () => setIsModalOpen(true);
//     const closeNewsModal = () => setIsModalOpen(false);
//
//     const navigate = useNavigate();
//     // const recaptchaContainer = document.getElementById('rc-anchor-alert');
//
//
//     const theme = useTheme();
//     const color = theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
//
//     const handleModalOpen = () => {
//         setOpenModal(true);
//     };
//
//
//     const handleModalClose = () => {
//         setOpenModal(false);
//     };
//
//     const [open, setOpen] = useState(false);
//
//     useEffect(() => {
//         const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
//         if (isMobile) {
//             setOpen(true);
//         }
//     }, []);
//
//
//
//
//     useEffect(() => {
//         fetchAudioFiles();
//     }, [category]);
//     const extractPeriod = (fileName) => {
//         if (fileName.toLowerCase().includes('evening')) {
//             return 'evening';
//         }
//         if (fileName.toLowerCase().includes('morning')) {
//             return 'morning';
//         }
//         return null;
//     };
//
//
//     const fetchAudioFiles = async () => {
//         try {
//             const response = await axios.get(backend+`api/files/category/${category}`);
//             // console.log(response);
//             // Sort files by date extracted from the name
//             // const sortedFiles = response.data.sort((a, b) => {
//             //     const dateA = extractDate(a.name)
//             //     const dateB = extractDate(b.name);
//             //
//             //     if (!dateA || !dateB) {
//             //         console.error("Invalid date format in file name:", a.name, b.name);
//             //         return 0;
//             //     }
//             //
//             //     return dateB - dateA;
//             // });
//             //
//             // setAudioFiles(sortedFiles);
//             // new paste
//             const sortedFiles = response.data.sort((a, b) => {
//                 const dateA = extractDate(a.name);
//                 const dateB = extractDate(b.name);
//
//                 if (!dateA || !dateB) {
//                     console.error("Invalid date format in file name:", a.name, b.name);
//                     return 0;
//                 }
//
//                 // Sort by date first
//                 if (dateA > dateB) return -1;
//                 if (dateA > dateB) return -1;
//                 if (dateA < dateB) return 1;
//
//                 // If dates are the same, prioritize evening news over morning news
//                 const periodA = extractPeriod(a.name);
//                 const periodB = extractPeriod(b.name);
//
//                 if (periodA === 'evening' && periodB === 'morning') return -1;
//                 if (periodA === 'morning' && periodB === 'evening') return 1;
//
//                 return 0;
//             });
//
//             setAudioFiles(sortedFiles);
//             // console.log(sortedFiles);
//
//             // Select the first audio file if available
//             const firstAudio = sortedFiles[0] ? sortedFiles[0].name : '';
//             // console.log(firstAudio);
//             setSelectedAudio(cloudFront+firstAudio);
//
//             // Set the source of the audio element if available
//             if (audioRef.current && firstAudio) {
//                 audioRef.current.src = firstAudio;
//             }
//         } catch (error) {
//             console.error("Error fetching audio files:", error);
//         }
//     };
//     useEffect(() => {
//         const fetchTranscript = async () => {
//             if (!selectedAudio) return;
//
//             // Extract the filename from the CloudFront URL
//             const filename = selectedAudio.replace(cloudFront, '');
//
//             try {
//                 // Fetch the file details from the backend
//                 const response = await axios.get(backend+`api/file-detail/${filename}/`);
//                 const transcript = response.data.transcript;
//
//                 // Set the transcript state
//                 setTranscript(transcript);
//                 setScrollPosition(50)
//             } catch (error) {
//                 console.error("Error fetching file details:", error);
//             }
//         };
//
//         fetchTranscript();
//     }, [selectedAudio]);
//
// // Helper function to extract date from file name
//     const extractDate = (fileName) => {
//         const datePart = fileName.split('_').pop().replace('.mp3', '');
//         const [month, day, year] = datePart.split('-');
//         if (month && day && year) {
//             return new Date(`${year}-${month}-${day}`);
//         }
//         return null;
//     };
//
//     const handleCategoryChange = (event) => {
//         setCategory(event.target.value);
//         setIsPlaying(false)
//     };
//
//     const initializeAudioMotionAnalyzer = () => {
//         if (audioRef.current && containerRef.current && !audioMotionRef.current) {
//             audioMotionRef.current = new AudioMotionAnalyzer(containerRef.current, {
//                 source: audioRef.current.audio.current,
//
//                 height: 300,
//                 ansiBands: false,
//                 showScaleX: false,
//                 bgAlpha: 0,
//                 overlay: true,
//                 mode: 6,
//                 frequencyScale: "bark",
//                 showPeaks: true,
//                 // gravity:10,
//                 smoothing: 0.1,
//                 ledBars: true,
//                 mirror:0,
//                 gradient:"orangered",
//
//             });
//
//         }
//     };
//
//     // const handleSkip = (seconds) => {
//     //     if (audioRef.current) {
//     //         audioRef.current.audio.current.currentTime += seconds;
//     //     }
//     // };
//
//     const handleChange = (event) => {
//         // console.log('handleChange', event);
//         const audioUrl = `${event.target.value}`;
//
//         setSelectedAudio(audioUrl);
//         if (audioRef.current) {
//             audioRef.current.audio.current.pause();
//             setIsPlaying(false);
//             audioRef.current.audio.current.src = audioUrl;
//             audioRef.current.audio.current.load();
//         }
//     };
//
//     const handlePlay = () => {
//         if (audioRef.current && audioRef.current.audio.current.readyState >= 2) {
//             initializeAudioMotionAnalyzer();
//             setIsPlaying(true);
//             audioRef.current.audio.current.play();
//         }
//     };
//
//     const handlePause = () => {
//         setIsPlaying(false);
//     };
//
//     const handleEnded = () => {
//         setIsPlaying(false);
//         if (audioRef.current) {
//             audioRef.current.audio.current.currentTime = 0;
//             audioRef.current.audio.current.pause();
//         }
//     };
//
//     const handlePlayClick = () => {
//         if (!isPlaying) {
//             handlePlay();
//         }
//     };
//
//     const handleTimeUpdate = () => {
//         if (audioRef.current) {
//             const currentTime = audioRef.current.audio.current.currentTime;
//             const duration = audioRef.current.audio.current.duration;
//
//             const progress = currentTime / duration;
//             const transcriptLength = transcript.length;
//             const displayedLength = Math.floor(progress * transcriptLength);
//             const newPosition = 50 - (progress * 100);
//             if (!isNaN(newPosition)) {
//                 setScrollPosition(newPosition);
//             }
//         }
//     };
//     const toggleTranscriptVisibility = () => {
//         setShowTranscript((prev) => !prev);
//     };
//
//     // closing mobile notificaiton
//     const handleClose = () => {
//         setOpen(false);
//     };
//
//     return (
//         <StyledContainer>
//                <Typography
//                     variant="h6"
//                     gutterBottom
//                     sx={{
//                         color: color, // Light grey color with low opacity
//                         textAlign: 'center', // Center the text
//                         // fontStyle: 'italic' // Optional: Add some style to make it more mysterious
//                     }}
//                 >
//                    News v2
//                 </Typography>
//
//
//
//             <Box ref={containerRef} style={{ width: '100%', height: '300px' }} />
//
//             <LiveTranscriptBox>
//                 {isPlaying && (
//                     <ScrollingText variant="body1" style={{ transform: `translateX(${scrollPosition}%)` }}>
//                         {transcript}
//                     </ScrollingText>
//                 )}
//             </LiveTranscriptBox>
//
//             <StyledFormControl  gap=".5rem" fullWidth>
//                 <InputLabel id="audio-select-label">News Report</InputLabel>
//                 <Select
//                     labelId="audio-select-label"
//                     value={selectedAudio}
//                     onChange={handleChange}
//                     label="Select Audio"
//                 >
//                     {audioFiles.slice(0, 14).map((file, index) => (
//                         <MenuItem key={index} value={`${cloudFront}${file.name}`}>
//                             {file.name.replace(category,"").replace(/_/g, ' ').replace('.mp3', '')}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </StyledFormControl>
//             <Box display="flex" justifyContent="center" alignItems="center" style={{marginTop:"10px",marginBottom:"10px"}} >
//                 <FormControl variant="outlined" sx={{ minWidth: 100 }} fullWidth>
//                     <InputLabel id="category-label">Category</InputLabel>
//                     <Select
//                         labelId="category-label"
//                         value={category}
//                         onChange={handleCategoryChange}
//                         label="Category"
//                     >
//                         <MenuItem value="Business">Business</MenuItem>
//                         <MenuItem value="Health">Health</MenuItem>
//                         <MenuItem value="Science">Science</MenuItem>
//                         <MenuItem value="Sports">Sports</MenuItem>
//                         <MenuItem value="Technology">Technology</MenuItem>
//                         <MenuItem value="Top">Top Headlines</MenuItem>
//
//
//
//
//                     </Select>
//                 </FormControl>
//                 <Box display="flex" justifyContent="center" >
//                     <IconButton onClick={openNewsModal}>
//                         <FormatListNumbered />
//                     </IconButton>
//
//                     <Tooltip title={showTranscript ? 'Hide Transcript' : 'Show Transcript'}>
//                         <IconButton onClick={toggleTranscriptVisibility}>
//                             {showTranscript ? <SpeakerNotesOff /> : <SpeakerNotes />}
//                         </IconButton>
//                     </Tooltip>
//                     <Tooltip title={"Full Screen View Transcript"}>
//
//                         <IconButton  onClick={handleModalOpen}>
//                             <DescriptionIcon />
//                         </IconButton>
//                     </Tooltip>
//
//                     <SpeedControl audioRef={audioRef} />
//
//                 </Box>
//             </Box>
//
//
//
//                 <CustomAudioPlayer
//
//                     ref={audioRef}
//                     src={selectedAudio}
//                     onPlay={handlePlay}
//                     onPause={handlePause}
//                     onEnded={handleEnded}
//                     onTimeUpdate={handleTimeUpdate}
//                     handleplayClick={handlePlayClick}
//                 />
//             <AIWarningAlert/>
//
//             {showTranscript && (
//                 <TranscriptBox>
//                     <Typography variant="body1">{transcript}</Typography>
//                 </TranscriptBox>
//             )}
//             <Dialog open={open} onClose={handleClose}>
//                 <DialogTitle>Welcome to News v2</DialogTitle>
//                 <DialogContent>
//                     <DialogContentText>
//                         This app hasn't been optimized for mobile devices. To activate the live transcript and audio
//                         visualization.<p><b style={{fontSize: "20px"}}>Click the Play Button Twice</b></p> We are
//                         currently building a mobile app for News v2 - for a more optimized experience on mobile
//                         platforms. <p><b>Access Newsv2.com from your computer for full functionality.</b></p>
//                         <p>Click below to get the latest news</p>
//                     </DialogContentText>
//                     <Box display="flex" justifyContent="center" marginTop={4}>
//                         <Button
//                             variant="contained"
//                             color="primary"
//                             startIcon={<FormatListNumbered />}
//                             onClick={() => navigate("/latest")}
//                         >
//                             Latest News
//                         </Button>
//                     </Box>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleClose} color="primary">
//                         OK
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//             <ModalComponent
//                 open={isModalOpen}
//                 handleClose={closeNewsModal}
//                 title="Latest News"
//                 content={<LatestNews/>} // Pass the LatestNews component as the modal content
//             />
//
//             <ModalComponent open={openModal} handleClose={handleModalClose} title={selectedAudio.replace(/_/g, ' ').replace('.mp3', '').replace(cloudFront,'')} content={transcript} />
//         </StyledContainer>
//     );
// };
//
// export default AudioPlayerPro;
//
