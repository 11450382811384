// src/components/Unsubscribe.js

import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { getCookie } from '../utils';

// const backend_url = "http://localhost:8000";
const backend_url = "https://api.newsv2.com"

function Unsubscribe() {

  const [email, setEmail] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [loading, setLoading] = useState(false); // Track loading state

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleUnsubscribe = async (e) => {
    e.preventDefault();
    const token = await window.grecaptcha.enterprise.execute('6Ld4akoqAAAAANqXxxnfKSzqs_u5tQX-r00rdXCp', { action: 'submit' });

    // Client-side email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setSnackbar({
        open: true,
        message: 'Email is required.',
        severity: 'warning',
      });
      return;
    }
    if (!emailRegex.test(email)) {
      setSnackbar({
        open: true,
        message: 'Invalid email address.',
        severity: 'error',
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      const csrfToken = getCookie('csrftoken'); // If CSRF protection is enabled
      const response = await axios.post(
          backend_url+'/api/unsubscribe',
          { email, captcha:token },
          {
            headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken, // Uncomment if CSRF token is required
            },
          }
      );
      setSnackbar({
        open: true,
        message: response.data.message,
        severity: 'success',
      });
      setEmail(''); // Reset the email field
    } catch (error) {
      setSnackbar({
        open: true,
        message:
            error.response?.data?.error ||
            'An error occurred. Please try again later.',
        severity: 'error',
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            Unsubscribe from Newsletter
          </Typography>
          <form onSubmit={handleUnsubscribe}>
            <TextField
                label="Email"
                type="email"
                required
                fullWidth
                margin="normal"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
                sx={{ mt: 2 }}
                disabled={loading} // Disable while loading
            >
              {loading ? 'Unsubscribing...' : 'Unsubscribe'}
            </Button>
          </form>
        </Box>
        <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
  );
}

export default Unsubscribe;
